<template>
  <div style="">
    <!-- 顶部横栏 -->
    <nav class="menuContent">
      <div class="headContent">
        <div class="headContentImgBox">
          <img src="../assets/iconCom.png" style="width: 80%; height: 100%" />
        </div>
        <div>
          <ul class="funWrapper">
            <li class="funItem" v-for="(item, index) in funList" :key="index">
              <a :href="item.href">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- 首页大图 -->
    <div
      id="mainPage"
      :class="[
        imgIndex == 1 ? 'showImg1' : '',
        imgIndex == 2 ? 'showImg2' : '',
        imgIndex == 3 ? 'showImg3' : '',
        'rotationChartBox',
      ]"
    >
      <div v-for="(item, index) in bac" :key="index">
        <img :src="item.img" :alt="item.alt" />
      </div>
    </div>
    <section>
      <!-- 共享经济模块 -->
      <div class="modBox" id="shareMenu">
        <div class="titleWrapper">
          <div class="titleBox">
            <div class="titleBegin"></div>
            <div style="font-size: 30px; font-weight: 400">共享解决方案</div>
            <div style="font-size: 14px; color: #8f959e; margin: 10px">
              提供多种类型设备的各个场所的共享服务
            </div>
          </div>
          <div class="menuCardBox">
            <div
              class="menuCard"
              v-for="(item, index) in shareModList"
              :key="index"
            >
              <div class="menuCard_imgBox">
                <img
                  :src="item.img"
                  style="width: 50%; height: 50%; padding: 50px"
                />
              </div>
              <div class="menuCard_text">
                <h2 class="menuCard_name">{{ item.name }}</h2>
                <div class="menuCard_desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 物联网和智能硬件解决方案 -->
      <div class="modBox" id="loTmod">
        <div class="titleBox">
          <div class="titleBegin"></div>
          <div style="font-size: 30px; font-weight: 400">
            物联网和智能硬件解决方案
          </div>
          <div style="font-size: 14px; color: #8f959e; margin: 10px">
            构建万物互联 智慧生活
          </div>
          <div class="menuCardBox">
            <!-- 到这里分开   -->
            <div
              v-for="(item, index) in lotModList"
              :key="index"
              @mouseenter="intoHover(index)"
              @mouseleave="outHover()"
            >
              <div class="lotMod" :class="[ activeHoverIndex==index&&isHovering?'lotModShow':'lotModHide','lotMod']">
                <img :src="item.img" style="width: 100%; height: 100%" />
                <div
                  class="lotMod_desc"
                  style="transition: 0.5s linear transform"
                >
                  {{ item.desc }}
                </div>
              </div>
              <div
                class="lotMod_detail"
                :class="[
                  activeHoverIndex==index&&isHovering ? 'lotMod_detailShow' : 'lotMod_detailHide',
                  'lotMod_detail',
                ]"
              >
                <div
                  class="lotMod_detail_item"
                  v-for="(item2, index2) in item.area"
                  :key="index2"
                >
                  {{ item2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 软件应用开发 -->
      <div id="softMenu">
        <div class="modBox">
          <div class="titleWrapper">
            <div class="titleBox">
              <div class="titleBegin"></div>
              <div style="font-size: 30px; font-weight: 400">软件定制开发</div>
              <div style="font-size: 14px; color: #8f959e; margin: 10px">
                APP 小程序 公众号 网站 一站式全套定制服务
              </div>
            </div>
            <div class="menuCardBox">
              <div
                class="customizedCard"
                v-for="(item, index) in customizedModList"
                :key="index"
              >
                <div class="softIcon">
                  <img :src="item.img" style="width: 80%; height: 80%" />
                </div>
                <h2 class="menuCard_name">{{ item.name }}</h2>
                <div class="menuCard_desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 关于我们 -->
      <div id="aboutUs">
        <div style="height: 400px">
          <img src="../../public/image/city.jpg" style="width:100%;height: 100%;"/>
        </div>
        <div style="padding: 40px">
          <h2 class="usTitle">关于码商</h2>
          <div class="usDesc">
            码商智能是一家专注于共享充电设备和互联网开发服务的公司。我们致力于为客户提供高质量、高效率、高附加值的服务，帮助他们在激烈的市场竞争中取得优势。总部位于深圳市，拥有一支由专业人才组成的团队。
            我们的业务范围包括共享充电设备的研发、生产、销售和售后服务，以及互联网开发服务。我们的产品广泛应用于共享充电、电商、金融、物流、教育等多个领域。
            我们的宗旨是：用专业的技术和高品质的服务，帮助客户实现商业价值的最大化。
            企业文化：
            我们的企业文化是：团结、创新、务实、高效。我们注重员工的培养和发展，提供广阔的职业发展空间和丰富的福利待遇，努力打造一个充满活力和创造力的工作环境。
            共享充电设备业务介绍：
            我们的共享充电设备采用了先进的智能技术和网络化管理，为客户提供便捷、高效、安全的充电服务。我们的设备具有智能化、自助化、快捷化、安全化等特点，深受广大用户的喜爱和好评。
            市场分析：
            共享充电设备市场是一个快速增长的市场，未来市场前景广阔。目前，国内共享充电设备的市场规模已经遍布周围生活，预计未来几年将保持高速增长。同时，随着共享经济的发展和人们对便捷、高效、安全的需求不断增加，共享充电设备市场将会持续扩大。
            竞争对手分析：
            目前，国内共享充电设备市场上，一些知名品牌如小电、来电、街电等已经占据了市场的主导地位。但是，我们相信，随着技术的不断创新和用户需求的不断提高，会有更多的企业加入到共享充电设备市场的竞争中来。
            目标用户群体描述：
            我们的目标用户群体主要是中小型企业和个人用户，他们需要高品质、便捷、安全的充电服务。
            销售策略和渠道建议：
            我们的销售策略主要是通过线上和线下渠道进行推广和销售。线上渠道包括电商平台、社交媒体等，线下渠道包括代理商、经销商、直营店等。我们将通过不断创新和优化服务，提高客户满意度和忠诚度，赢得市场的竞争优势。
            服务设计领域介绍：
            我们的服务涵盖了互联网开发的各个领域，包括前端设计、后端开发、UI设计、运营推广等。我们的服务优势在于：专业、高效、创新、实用。我们的服务创新点在于：将传统的互联网开发服务与人工智能、大数据等新兴技术相结合
          </div>
        </div>
      </div>

      <!-- 底部icp区域信息 -->
      <div class="bottomStyle">
        <div style="width: 70%; margin: auto">
          <div class="icpWrapper">
            <div class="icpWrapperLeft">
              <div style="padding: 40px">
                <img src="../assets/iconCom.png" style="width: 80%" />
              </div>
              <div style="padding: 20px; color: #fff">
                <h2 style="color: #f5c751">400-119-2558</h2>
                <div>深圳市龙岗区岭南湾科创楼411</div>
              </div>
            </div>
            <div class="icpWrapperRight">
              <div>
                <h3>解决方案</h3>
                <div v-for="(item, index) in shareModList" :key="index">
                  {{ item.name }}
                </div>
              </div>
              <div>
                <h3>软件方案</h3>
                <div v-for="(item, index) in customizedModList" :key="index">
                  {{ item.name }}
                </div>
              </div>
              <div>
                <h3>智能硬件</h3>
                <div v-for="(item, index) in lotModList" :key="index">
                  {{ item.desc }}
                </div>
              </div>
              <div>
                <h3>联系我们</h3>
                <div>400-119-2558</div>
                <div>694180248@qq.com</div>
              </div>
            </div>
          </div>
          <div class="icpText">
            ©2021-2023 深圳市码商智能科技有限公司 | 粤ICP备19094817号
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "WebPage",
  data() {
    return {
      activeHoverIndex:-1,
      funList: [
        {
          id: 1,
          name: "首页",
          href: "#mainPage",
        },
        {
          id: 2,
          name: "共享解决方案",
          href: "#shareMenu",
        },
        {
          id: 3,
          name: "物联网和智能硬件解决方案",
          href: "#loTmod",
        },
        {
          id: 4,
          name: "软件应用开发",
          href: "#softMenu",
        },
        {
          id: 5,
          name: "关于码商",
          href: "#aboutUs",
        },
        {
          id: 5,
          name: "400-119-2558",
          href: "#mainPage",
        },
      ],
      imgIndex: 1,
      timer: null,
      bac: [
        {
          img: require("../../public/image/bac1.jpg"),
          alt: "首页图片1",
        },
        {
          img: require("../../public/image/bac2.jpg"),
          alt: "首页图片2",
        },
        {
          img: require("../../public/image/bac3.jpg"),
          alt: "首页图片3",
        },
      ],
      shareModList: [
        {
          id: 0,
          img: require("../../public/image/chongdianqi.png"),
          name: "共享充电器",
          desc: "省去了携带、收纳充电器的烦恼；适合短时间出门的人，随时随地可以充电，尤其是生活在快节奏城市的年轻人；有利于用户节省个人的时间，缓解了电源需求的压力",
        },
        {
          id: 1,
          img: require("../../public/image/chongdianbao.png"),
          name: "共享充电宝",
          desc: "随时随地可以充电，无需担心电源问题.适合急需用电但无法及时满足的用户，比如旅行、商务出差等.使用起来非常方便，用户只需要扫描二维码即可租借使用，充电速度也很快",
        },
        {
          id: 2,
          img: require("../../public/image/diandongche.png"),
          name: "电动车充电桩",
          desc: "方便快捷,电动车充电桩可以随时随地充电，无需为充电排队等待.安全可靠：电动车充电桩配备多重保护功能，能够有效防止电池过充、过放和短路，保障用户的安全",
        },
        {
          id: 3,
          img: require("../../public/image/a-chongdianzhuang2.png"),
          name: "汽车充电桩",
          desc: "为车主提供快速、便利的充电服务，不受场地和时间限制，满足车主的日常用车需求。可以根据不同的需求提供不同的充电功能，如快速充电、慢速充电、定时充电等.",
        },
      ],
      customizedModList: [
        {
          id: 0,
          img: require("../../public/image/xiaochengxu.png"),
          name: "小程序",
          desc: "轻量化、快速启动、无需下载安装、可离线使用、可与社交平台融合",
        },
        {
          id: 0,
          img: require("../../public/image/SaaS.png"),
          name: "SaaS服务",
          desc: "基于云服务的软件模式，具有灵活性高、成本低、可远程协作、可定制性强、安全性高",
        },
        {
          id: 0,
          img: require("../../public/image/Android.png"),
          name: "安卓",
          desc: "开放性、兼容性强、功能丰富、可自由定制",
        },
        {
          id: 0,
          img: require("../../public/image/IOS.png"),
          name: "IOS",
          desc: "安全性高、用户黏性强、用户支付意愿高、用户体验好",
        },
      ],
      lotModList: [
        {
          img: require("../../public/image/dz_jpg.jpg"),
          desc: "物联网应用定制",
          area: ["APP", "微信", "小程序"],
        },
        {
          img: require("../../public/image/xp_jpg.jpg"),
          desc: "物联网智能硬件",
          area: [
            "智能家居",
            "智能城市",
            "智能农业",
            "智能医疗",
            "智能物流",
            "智能制造",
          ],
        },
        {
          img: require("../../public/image/lot_jpg.jpg"),
          desc: "物联网loT云平台",
          area: [
            "低成本快速接入",
            "兼容多协议",
            "高并发量",
            "大数据分析",
            "可视化运维管理",
            "安全可靠",
          ],
        },
      ],
      isHovering: false,
    };
  },
  mounted() {
    //轮播
    this.timer = setInterval(() => {
      console.log(this.imgIndex);
      this.imgIndex++;
      if (this.imgIndex > 3) {
        this.imgIndex = 1;
      }
    }, 3000);
  },
  computed: {},
  methods:{
    intoHover(index){
      this.isHovering = true;
      this.activeHoverIndex=index
      console.log(index)
    },
    outHover(){
      this.isHovering = false
      this.activeHoverIndex=-1
      console.log( this.activeHoverIndex)
    }
  }
};
</script>
  
<style scoped>
.menuContent {
  height: 100px;
  background: #087ff5;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  border-top: 1px solid #dbdcdd;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
}

.headContent {
  /* border: 1px solid; */
  display: flex;
  justify-content: space-around;
}
.menuContent div {
  height: 100%;
  box-sizing: border-box;
  line-height: 100px;
}
li {
  list-style: none;
  line-height: 100px;
}
.funItem:hover {
  cursor: pointer;
  border-bottom: 2px solid #d9dadbcb;
}
.funWrapper {
  display: flex;
  justify-content: space-around;
  line-height: 3;
  font-size: 20px;
  gap: 35px;
  margin: 0 10px;
  height: 100%;
}
.funWrapper a {
  font-size: 16px;
  text-decoration: none;
  color: #f6f7f8;
}
.rotationChartBox {
  width: 100vw;
  height: 70vh;
  padding-top: 92px;
  display: flex;
  transition: 0.5s ease-in-out transform;
  /* overflow: hidden; */
}
.showImg1 {
  transform: translateX(0vw);
}
.showImg2 {
  transform: translateX(-100vw);
}
.showImg3 {
  transform: translateX(-200vw);
}

.rotationChartBox div {
  flex-shrink: 0;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  color: #f6f7f8;
  position: relative;
}
/* .rotationChartBox div img{
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
} */
.rotationChartBox img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover; 

}
.headContentImgBox {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* padding-top: 15px; */
}
.modBox {
  text-align: center;
  margin: 80px auto;
}
.titleBegin {
  margin: 0 auto 16px;
  width: 24px;
  height: 4px;
  background-color: #3370ff;
  border-radius: 100px;
}
.menuCardBox {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  gap:45px
}
.menuCard {
  flex: 1;
  /* border: 1px solid; */
  background-color: #fafafa;
}
.customizedCard {
  flex: 1;
  transition: 0.5s linear transform;
}
/* .customizedCard :hover {
  transform: scale(1.2);
  cursor: pointer;
} */

.menuCard_imgBox {
  /* background-color: rebeccapurple; */
  height: 250px;
}
.menuCard div {
  box-sizing: border-box;
}
.menuCard_text {
  flex: 3;
  margin: 20px auto;
  transition: 0.5s linear transform;
}
.menuCard_text:hover {
  cursor: pointer;
  transform: translateY(-30px);
}
.menuCard_name {
  margin: 15px 0 0 0;
  color: #333;
  font-size: 20px;
}
.menuCard_desc {
  text-align: left;
  width: 70%;
  color: #999;
  font-size: 14px;
  margin: 25px auto;
}
.titleBox {
  margin: 50px auto;
  padding-top: 30px;
}
.softIcon {
  /* background-color: yellow; */
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  transition: 0.5s linear transform;
}
.softIcon:hover {
  cursor: pointer;
  transform: scale(1.2);
}
#softMenu {
  background: #f8fbff;
}

.lotMod {
  width: 100%;
  background-color: #087ff5;
  width: 400px;
  height: 200px;
  /* border-radius: 20px; */
  overflow: hidden;
  position: relative;
  flex: 1;
  transition: 0.5s ease-in-out;
}
.lotModShow{
  /* transform: scale(1.1); */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
}
.lotModHide{
  border-radius: 20px;
}

.lotMod_desc {
  position: absolute;
  width: 100%;
  background: rgb(0 0 0 / 15%);
  bottom: 0;
  padding: 15px;
  color: #f6f7f8;
}
.lotMod_detailShow {
  color:#595959;
  /* background-color: #eeecec; */
  /* border: 1px solid #eeecec; */
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #eeecec;
}
.lotMod_detail {
  cursor: pointer;
  text-align: left;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  /* border: 1px solid #eeecec; */
}
.lotMod_detailHide {
  border: 1px solid #eeecec;
  height: 0;
  padding: 0;
  border: none;
}
.bottomStyle {
  background-color: #393939;
  margin: 50px 0 0;
}
.icpText {
  text-align: center;
  padding: 20px;
  color: #f8fbff;
}
.icpWrapper {
  width: 100%;
  display: flex;
}
.icpWrapper div {
  flex: 1;
}
.icpWrapperLeft {
  display: flex;
}
.icpWrapperRight {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.usDesc {
  color: #999;
  text-indent: 2em;
}
.usTitle {
  border-left: 8px solid #087ff5;
  padding-left: 10px;
}

.lotMod_detail_item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.lotMod_detail_item:last-child {
  border-bottom: none;
}
</style>
  