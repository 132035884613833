<template>
  <WebPage />
</template>

<script>
// import Main from './components/Main.vue'
import WebPage from './components/WebPage.vue'

export default {
  name: 'App',
  components: {
    WebPage,
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-width: 1600px;
}
</style>
